import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";

export const PatientInhetedRetinalEs = styled.div`
  .patient-inherited-retinal {
    @media ${device.ipadair} and ${device.laptopair2max} {
      max-width: 1208px;
      margin-left: auto;
      margin-right: auto;
    }

    .clinical-trials-banner {
      @media ${device.tablet} {
        height: unset;
      }
      @media ${device.ipadLandscapemin} {
        height: auto;
      }
    }
    .footer-block--row {
      @media ${device.laptopMedium} {
        margin-bottom: -200px !important;
      }
      @media ${device.ipadLandscapemin} {
        padding: 0 95px;
      }
      @media ${device.desktopsignup} {
        padding: 0 88px;
      }
    }
    .infographic-wrapper {
      > span {
        ~ .o-container {
          padding: 0;
        }
      }
    }

    .video-section {
      &:last-child {
        .o-container {
          .video-section {
            .video-section__inner-content {
              .o-button.video-section__inner-content__video-btn {
                @media ${device.laptopMedium} {
                  margin-left: 7px;
                }
              }
            }
          }
        }
      }
    }
    .video-section__inner-content {
      .o-header {
        @media ${device.tablet} {
          margin-top: 55px;
        }

        @media ${device.ipadLandscapemin} {
          margin-top: 0;
        }

        @media ${device.mobileMedium} {
          margin-top: 0;
        }
      }
    }
    .genetic-cause__left-img-secion {
      .percentblock {
        font-size: 2.2rem;
        line-height: 1.4rem;
        margin-top: 33px;
      }
      .percentcount {
        .percent_text {
          margin-top: 10px;
        }
      }
    }
    .wrap {
      &:before {
        @media ${device.tablet} and ${device.laptopair2max} {
          background-color: #00ffd9 !important;
        }
      }
      > div {
        @media ${device.tablet} and ${device.laptopair2max} {
          max-width: 90%;
        }
      }
      .video-section__inner-content {
        @media ${device.tablet} {
          margin-top: -50px !important;
        }

        p {
          @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
            margin-bottom: 70px;
          }

          span {
            font-weight: 700;
            color: rgb(128, 0, 190);
          }
        }
      }

      .home-video-section {
        @media ${device.tablet} and ${device.laptopair2max} {
          padding: 0 100px;
        }
        .video-section__inner-content {
          .o-button {
            @media ${device.mobileMedium} {
              margin-left: 15px !important;
            }
            @media ${device.tablet} and ${device.laptopair2max} {
              margin-left: 20px;
            }
          }
        }
      }

      .video-section {
        .o-container {
          .video-section {
            .video-section__inner-content {
              .o-button.video-section__inner-content__video-btn {
                @media ${device.mobileMedium} {
                  margin-left: 7px;
                }
              }
            }
          }
        }
      }
    }

    .video-section__inner-content {
      p {
        font-weight: 500;
      }
      .home-video-section {
        .video-section__inner-content__video-btn {
          i.rotate {
            &:before {
              height: 19px !important;
            }
          }
        }
      }
    }
    .video-section {
      .o-container {
        .video-section {
          .video-section__inner-content {
            .o-button.video-section__inner-content__video-btn {
              i.rotate {
                &:before {
                  height: 19px !important;
                }
              }
            }
          }
        }
      }
    }

    .footer-block--row {
      .footer-inner-block {
        width: 87%;
        @media ${ device.mobileMedium} {
          width: 254px;
        }
        @media ${device.desktopsignup} {
          padding: 32px 32px 120px;
        }
        @media ${device.ipadLandscapemin} {
          width: calc(48% - 26px);
        }

        .o-common-para {
          @media ${device.desktopsignup} {
            padding: 20px 5px 20px 5px;
          }
        }

        .o-common-title {
          @media ${device.desktopsignup} {
            padding: 0 12px;
          }
        }
      }
    }
    .accordion-section {
      .panel-group {
        .panel {
          .panel-collapse {
            .panel-body {
              .listing-section {
                &.level-two {
                  padding-left: 38px !important;
                  @media ${device.ipadLandscapemin} {
                    padding-left: 112px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .inherited-para {
      padding-top: 80px;
      padding-bottom: 88px;

      @media ${device.laptopMedium} {
        padding-top: 27px;
        padding-bottom: 44px;
      }

      .o-inner {
        h2 {
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 5rem;
          color: rgb(0, 0, 0);
          text-align: center;

          @media ${device.laptopMedium} {
            max-width: 400px;
            margin: auto;
            font-size: 2.5rem;
            line-height: 3rem;
          }

          @media (max-width: 575px) {
            max-width: 100%;
            letter-spacing: normal;
          }
        }
        p.o-paragraph {
          padding-top: 48px;
          max-width: 768px;
          margin: auto;
          text-align: center;
          color: rgb(0, 0, 0);
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;

          @media ${device.laptopMedium} {
            line-height: 2.2rem;
            padding: 15px 0px 0px;
            font-size: 1.8rem;
            font-weight: 500;
          }
        }
      }
    }
    .genetic-cause__left-img-secion {
      .percentblock {
        display: block;
        margin: 25px auto auto;
        font-family: Gotham, sans-serif;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: rgb(0, 0, 0);
        text-align: center;
        font-weight: 700;

        @media ${device.mobileMedium} {
          font-size: 1rem !important;
        }
        @media ${device.laptopMedium} {
          font-size: 1.1rem;
        }
      }

      .percentcount {
        padding-right: 0;
      }
    }

    .infographic-wrapper {
      .youdohtml {
        .inherit-button-wrapper {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          padding-bottom: 100px;

          .media {
            width: 100%;

            a {
              margin: 48px auto auto;
              text-align: center;
              box-shadow: rgb(214, 214, 214) 0px 2px 4px;
              padding: 14px 22px;
              color: rgb(255, 255, 255);
              border: 1px solid rgb(255, 255, 255);
              font-weight: 500;
              font-size: 2.2rem !important;
              text-decoration: none;
              z-index: 1;
              position: relative;
              display: inline-block;
              vertical-align: top;
              overflow: hidden;
              letter-spacing: 0px;
              border-radius: 6px;
              cursor: pointer;
              background: rgb(128, 0, 190) !important;
              width: 85%;
              margin-left: 7%;

              &:hover {
                color: rgb(128, 0, 190);
                border-color: rgb(255, 255, 255);
                font-weight: 700;
                box-shadow: rgb(214, 214, 214) 0px 3px 4px;
                background: rgb(255, 255, 255) !important;
              }

              &:after {
                margin-left: 0 !important;

                @media ${device.laptopMedium} {
                  margin: 5px auto !important;
                }
              }
            }
          }
        }
      }
    }
    .accordion-section {
      p.disclaimer-paragraph {
        margin-top: 0 !important;
      }
    }

    .footer-block--row {
      .footer-inner-block {
        &:first-child {
          a {
            &:after {
              width: 19px;
            }
          }
        }

        &:last-child {
          a {
            padding: 16px 25px;

            &:after {
              width: 24px;
              left: 0;

              @media ${device.mobileMedium} {
                left: 8px;
              }
            }
          }
        }
        a {
          &:after {
            background-repeat: no-repeat !important;
          }
        }
      }
    }

    .infographic-wrapper {
      .youdohtml {
        .inherit-button-wrapper {
          @media ${device.laptopMedium} {
            padding-bottom: 25px;
          }
        }
      }
    }
  }
`;

export const InheritedRetinalDiseasesPatient = styled.div`
  .acsbMenu {
    .inherited-para {
      padding-top: 88px;
      padding-bottom: 34px;

      @media ${device.laptopMedium} {
        padding-top: 27px;
        padding-bottom: 44px;
      }

      .o-container {
        @media ${device.mobileMedium} {
          padding: 0 2.7rem;
        }
      }

      .o-inner {
        padding-left: 100px;
        padding-right: 100px;

        @media ${device.laptopMedium} {
          padding-left: 0px;
          padding-right: 0px;
        }

        .o-internal--h2 {
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 5rem;
          color: #000000;
          text-align: center;

          @media ${device.laptopMedium} {
            max-width: 238px;
            margin: auto;
            font-size: 2.5rem;
            line-height: 3rem;
          }

          @media (max-width: 575px) {
            max-width: 100%;
            letter-spacing: normal;
          }
        }
        .o-paragraph {
          font-size: 2rem;
        }
      }
      p {
        padding-top: 35px;
        margin: auto;
        text-align: center;
        color: #000000;
        font-size: 2.2rem !important;
        font-weight: 500;
        line-height: 3.2rem;

        @media ${device.laptopMedium} {
          line-height: 2.2rem;
          padding: 15px 0 0;
          font-size: 1.8rem !important;
          font-weight: 500;
        }
      }
    }
  }
  .o-container {
    max-width: 1208px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
  }
  .internal-banner__contant {
    .o-container {
      h1 {
        p {
          color: #ffffff !important;
          line-height: 6.4rem;
          span {
            color: #00ffd9 !important;
          }

          @media ${device.laptopMedium} {
            line-height: 3.8rem !important;
          }
        }
      }
    }
  }

  .video-section__inner-content {
    .o-container {
      padding: 0;
    }
    .home-video-section {
      .video-section__inner-content__video-btn {
        span {
          font-size: 2.2rem;
        }
        i {
          &:before {
            height: 10px !important;
          }
        }
      }
    }
  }

  .eyeView {
    padding-left: 100px;
    padding-right: 100px;
    max-width: 1208px;
    margin: 0 auto;

    @media ${device.laptopMedium} {
      padding-left: 0;
      padding-right: 0;
    }

    h2 {
      max-width: 865px;
      margin: 0 auto;
    }
    .generic-slider-no-image {
      padding: 0;

      .slider-arrow-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 48px;

        .slider-btn {
          width: 137px;
          height: 54px;
          background-color: #7323ca;
          margin: 0px 16px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          cursor: pointer;

          @media ${device.laptopMedium} {
            width: 75px;
            height: 52px;
            margin: 0px 10px;
          }
        }

        .swiper-button-disabled {
          background-color: #7323ca;
          opacity: 0.3;
        }
      }
      .swiper-slide {
        margin-bottom: 0 !important;

        .wizard-paragraph {
          font-size: 2rem;
          padding-right: 0;
        }

        &.swiper-slide-active {
          @media ${device.ipadair} and ${device.laptopair2max} {
            width: 784px;
          }
        }
      }
      .swiper-wrapper {
        > .swiper-slide {
          .swiper-slide {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .infographic-wrapper {
    max-width: 1168px;

    > div.o-container {
      @media ${device.laptopMedium} {
        padding: 0;
        width: 100%;
      }
    }

    .o-container {
      @media ${device.laptopMedium} {
        width: 87%;
      }
    }

    .discussion-guide {
      padding: 104px 47px 0 90px;

      @media ${device.laptopMedium} {
        padding: 0;
      }

      .o-header {
        text-align: center;
      }

      .o-paragraph {
        max-width: 100% !important;
        @media ${device.laptopMedium} {
          font-size: 1.8rem !important;
        }

        span {
          font-weight: 700;
          color: rgb(128, 0, 190);
        }
      }
    }

    .youdohtml {
      .inherit-button-wrapper {
        @media ${device.desktopsignup} {
          padding-bottom: 66px;
        }

        a {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 55px;
          margin-right: 55px;
          text-align: center;
          margin-top: 48px;
          box-shadow: 0px 2px 4px #d6d6d6;
          background: #8000be !important;
          color: #fff;
          border: 1px solid #fff;
          font-weight: 500;
          text-decoration: none;
          z-index: 1;
          position: relative;
          display: inline-block;
          vertical-align: top;
          overflow: hidden;
          letter-spacing: 0;
          border-radius: 6px;
          cursor: pointer;
          padding: 14px 35px !important;
          font-size: 2rem !important;
          line-height: 2.3rem;
          align-items: center;
          flex-basis: fit-content;
          width: 75%;
          display: block;

          @media ${device.ipadLandscapemin} {
            width: 75%;
            margin: 0 2%;
          }
          @media ${device.laptop} {
            display: flex;
          }

          span {
            display: contents;
          }

          &:hover {
            background: #fff !important;
            color: #8000be;
            border-color: #fff;
            font-weight: 700;
            box-shadow: 0px 3px 4px #d6d6d6;
          }

          &:after {
            content: "";
            display: block;
            height: 20px;
            width: 16px;
            margin: 0 auto;

            @media ${device.laptop} {
              display: inline-block;
              margin-left: 15px;
            }
          }

          @media ${device.mobileMedium} {
            width: 100%;
            margin: auto;
            display: block;
          }

          br {
            @media ${device.mobileMedium} {
              display: none;
            }
          }

          @media ${device.laptopMedium} {
            margin: 15px auto;
          }
        }
      }
    }
    .inherit-button-wrapper {
      a {
        padding: 14px 35px !important;
        font-size: 1.7rem !important;

        @media ${device.laptopMedium} {
          font-size: 1.8rem !important;
        }

        span {
          color: #00ffd9;
        }
      }
    }
  }
  .disease-from-another {
    h3 {
      @media ${device.laptopMedium} {
        width: 90%;
      }
    }
    p {
      font-size: 2.2rem !important;

      @media ${device.laptopMedium} {
        font-size: 1.8rem !important;
        width: 96%;
      }
    }
    .listing-section {
      li {
        padding: 6px 0 6px 25px !important;
        @media ${device.laptopMedium} {
          font-size: 1.8rem !important;
        }
        @media ${device.mobileMedium} {
          line-height: 2.2rem !important;
          font-size: 1.8rem !important;
        }
        @media ${device.ipadLandscapemin} {
          line-height: 2.2rem !important;
        }
        &:before {
          @media ${device.ipadLandscapemin} {
            top: 30px !important;
          }
        }
      }
    }
  }
  .accordion-link-text {
    padding-top: 17px !important;
    a {
      @media ${device.laptopMedium} {
        font-size: 1.8rem !important;
      }
    }
  }
  .accordion-section {
    .panel-group {
      .panel {
        .panel-collapse {
          .panel-body {
            .listing-section {
              padding: 0 100px 14px 80px !important;

              @media ${device.laptopMedium} {
                padding: 0 15px !important;
              }

              &:nth-child(2) {
                margin-top: -20px !important;
                li {
                  padding: 0 0 20px 20px !important;
                }
              }
              li {
                padding: 0 0 20px 25px !important;
                @media ${device.laptopMedium} {
                  font-size: 1.8rem !important;
                }
                &:first-child {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
    p.disclaimer-paragraph {
      margin-top: 90px !important;

      @media ${device.laptopMedium} {
        margin-top: 35px !important;
      }
    }
  }

  .footer-block--row {
    .footer-inner-block {
      .o-common-title {
        font-size: 2.7rem !important;

        @media ${device.laptopMedium} {
          font-size: 2rem !important;
        }
        &:before {
          display: none !important;
        }
      }

      &:first-child {
        margin-left: 32px;

        @media ${device.laptopMedium} {
          margin: 0 auto !important;
        }

        img {
          width: 51px;
          padding-top: 8px;
          padding-bottom: 8px;

          @media ${device.laptopMedium} {
            width: 33px;
            padding-bottom: 20px;
            padding-top: 20px;
          }
        }
        a {
          &:after {
            left: 1px;
            position: relative;
            width: 25px;
            @media ${device.mobileMedium} {
              width: 29px;
              background-repeat: no-repeat;
            }
          }
        }
      }
      &:last-child {
        @media ${device.laptopMedium} {
          margin: 20px auto !important;
        }
        img {
          width: 39px;
          padding-top: 8px;
          padding-bottom: 20px;

          @media ${device.laptopMedium} {
            width: 33px;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        .o-common-title {
          @media ${device.laptopMedium} {
            width: 90%;
            margin: 0 auto;
          }
        }
        a {
          padding: 16px 60px;
          &:after {
            left: 15px;
            position: relative;
            width: 30px;

            @media ${device.mobileMedium} {
              left: 8px;
              width: 26px;
              background-repeat: no-repeat;
            }
          }

          @media ${device.mobileMedium} {
            padding: 16px 19px;
          }
        }
      }

      a {
        font-size: 1.8rem !important;
        line-height: 2.1rem !important;
        align-items: center;
        max-width: 300px;
        margin: 0 auto !important;
        display: flex !important;
        justify-content: center;

        @media ${device.mobileMedium} {
          display: block !important;
        }

        &:first-child {
          &:after {
            left: 1px;
            position: relative;
            width: 23px;
          }
        }
      }
    }
  }
  .video-section__inner-content {
    > div {
      @media ${device.tabletMax} {
        width: 96% !important;
        margin-left: 8px;
      }
    }
  }
`;

export const YouAreNotAlone = styled.div`
  margin-bottom: 84px;

  @media ${device.laptopMedium} {
    margin-bottom: 10px;
  }

  .o-container {
    @media ${device.laptopMedium} {
      padding: 0 2.7rem;
    }
    .info-box {
      display: flex;
      align-items: center;
      background-color: #300064;
      padding: 27px 85px;
      position: relative;
      margin: auto;

      @media ${device.laptopMedium} {
        padding: 24px 40px;
        display: block;
      }

      .media {
        img {
          position: absolute;
          left: 90px;
          top: 45px;

          @media ${device.laptopMedium} {
            position: unset;
            left: 0;
            right: 0;
            text-align: center;
            display: block;
            margin: auto;
            width: 45px;
          }
        }
      }

      .o-paragraph {
        text-align: left;
        color: #fff;
        padding: 0 0 0 101px;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.2rem;
        margin-bottom: 0;

        @media ${device.laptopMedium} {
          text-align: center;
          margin-top: 10px;
          padding: 0;
          display: block;
          line-height: 2.4rem;
          font-size: 1.8rem;
        }

        @media ${device.tabletMax} {
          display: inline-block;
        }

        span {
          display: inline-block;
          color: #00ffd9;
        }

        span.infobox-small-paragraph {
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
          font-size: 1.8rem;
          line-height: 3.2rem;
          color: #fff;
          padding-top: 13px;

          @media ${device.laptopMedium} {
            font-size: 1.4rem;
            line-height: 1.8rem;
            padding-top: 13px;
          }
        }
      }
    }
  }
`;

export const GeneVariants = styled.div`
  margin-bottom: 80px;
  width: 1170px;
  margin: auto;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);

  @media ${device.laptopMedium} {
    max-width: 718px;
    width: auto;
    padding: 0 2.7rem;
    box-shadow: none;
  }

  .brightcove-react-player-loader {
    .video-js {
      @media ${device.tabletMax} {
        margin: 0 auto;
      }
    }
  }

  .faq-container {
    position: relative;
    background-color: #f7f7f7;
    max-width: 1170px;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    padding: 0;

    @media ${device.laptopMedium} {
      max-width: 718px;
      padding: 0 2.7rem;
    }
    @media ${device.mobileMedium} {
      padding: 0 4.2rem;
    }

    &:before {
      content: "";
      background: linear-gradient(
        90deg,
        #8000be 0%,
        #594dc6 35%,
        #17d0d4 64%,
        #00ffd9 100%
      );
      position: absolute;
      top: 0;
      width: 100%;
      height: 16px;
      left: 0;
      right: 0;
      z-index: 1;
    }
    .bg-color-component {
      .faq-block {
        position: relative;
        margin-top: 120px;

        @media ${device.laptopMedium} {
          padding-left: 10px;
          padding-right: 10px;
        }
        @media ${device.mobileMedium} {
          padding-left: 0;
          padding-right: 0;
        }

        .o-top-space {
          padding-top: 88px;

          @media ${device.laptopMedium} {
            padding-top: 48px;
          }

          @media ${device.mobileMedium} {
            padding-top: 3.5rem;
          }

          h2 {
            padding-top: 16px;
            color: #000000;
            text-align: center;
            padding-bottom: 40px;
            font-size: 4.2rem;
            font-weight: 600;
            line-height: 5rem;
            margin-bottom: 0;

            @media ${device.laptopMedium} {
              margin-top: 0;
              padding: 0;
              font-size: 2.5rem;
              line-height: 3rem;
            }
          }
        }
        .o-inner {
          margin: auto;
          position: relative;
        }
        .media {
          float: left;

          @media ${device.laptopMedium} {
            float: unset;
          }

          img {
            width: 68px;

            @media ${device.laptopMedium} {
              width: 33px;
              margin: 0 auto;
              display: block;
              margin-top: 22px;
              margin-bottom: 12px;
            }
          }
        }

        .faq-question {
          position: relative;

          @media ${device.desktopsignup} {
            margin-left: 50px;
          }

          .faq-answer__faq-content {
            @media ${device.tabletMax} {
              width: 90%;
              margin: 0 auto;
            }
            @media ${device.mobileMedium} {
              width: 100%;
            }
          }

          &:last-child {
            .faq-answer__faq-content {
              @media ${device.desktopsignup} {
                width: 98%;
              }
            }
          }
        }
      }
      .faq-answer__faq-title,
      .faq-answer__faq-content {
        padding-left: 100px;
      }
      .faq-answer__faq-title {
        font-weight: 700;
        color: #8000be;
        font-size: 2.7rem;
        line-height: 3.8rem;
        font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
        margin-bottom: 0;

        @media ${device.laptopMedium} {
          font-size: 2rem;
          line-height: 2.4rem;
          padding-left: 0;
          margin: 0 auto;
          display: table;
        }
        strong {
          font-weight: 700;
        }
      }
      .faq-answer__faq-content {
        font-weight: 500;
        padding-top: 32px;
        font-size: 2.2rem;
        line-height: 3.2rem;
        margin-bottom: 48px;

        @media ${device.laptopMedium} {
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding-top: 12px;
          padding-left: 0;
          text-align: center;
          margin-bottom: 44px;
        }

        span {
          color: #8000be;
          font-weight: 700;

          strong {
            font-weight: 700;

            @media ${device.mobileMedium} {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
  > div.video-section__inner-content {
    @media ${device.laptopMedium} {
      box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
      padding-bottom: 32px;
      padding-top: 0px !important;
    }
    @media ${device.tabletMax} {
      margin-top: 4px !important;
    }
    @media ${device.tablet} and ${device.laptopair2max} {
      margin-top: -40px !important;
    }

    &:before {
      @media ${device.laptopMedium} {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        height: 8px;
        background-color: #f7f7f7;
        z-index: 10;
        top: -5px;
      }
    }
  }
  .video-section__inner-content {
    @media ${device.laptopMedium} {
      padding-top: 30px !important;
    }

    div {
      @media ${device.laptopMedium} {
        width: 100%;
      }
    }

    p {
      @media ${device.laptopMedium} {
        padding-bottom: 28px;
        width: 72%;
      }
    }

    .video-section__inner-content__video-btn {
      @media ${device.laptopMedium} {
        width: max-content;
      }
      @media ${device.tabletMax} {
        margin-left: -8px;
        width: max-content;
      }
      span {
        font-size: 2.2rem;

        @media ${device.laptopMedium} {
          font-size: 1.8rem !important;
        }
      }
    }

    .video-section {
      border-bottom: none;
      background-color: #f7f7f7;

      @media ${device.laptopMedium} {
        padding-bottom: 30px;
      }
    }
    .o-header {
      max-width: 700px;
      padding-bottom: 48px;
      font-size: 4.2rem;
      text-align: center;
      font-weight: bold;
      margin: auto;
      padding-top: 38px;

      @media ${device.laptopMedium} {
        padding-bottom: 20px;
        font-size: 2.5rem;
        line-height: 3rem;
        width: 80%;
      }
    }

    p {
      font-size: 2.2rem;
      line-height: 3.2rem;
      text-align: center;
      max-width: 768px;
      margin: auto;
      font-weight: 500;

      @media ${device.laptopMedium} {
        font-size: 1.8rem;
        width: 88%;
        line-height: 1.3;
        display: block;
        margin-bottom: 20px;
      }
      @media ${device.tabletMax} {
        width: 80%;
        margin-bottom: 45px;
      }

      @media ${device.smalldeskstrt} {
        margin-bottom: 45px;
      }
    }

    .o-button {
      &.video-section__inner-content__video-btn {
        background: #8000be;
        color: #fff;
        border: 1px solid #fff;
        margin-left: 0;

        &:hover {
          background: #fff;
          color: #8000be;
          border-color: #fff;
          font-weight: 700;
        }

        i {
          &:before {
            height: 19px;
          }
        }
      }
    }
  }
`;

export const Card = styled.div`
  padding: 120px 0;
  padding-bottom: 48px;

  @media ${device.laptopMedium} {
    padding-top: 40px;
    width: 90%;
    margin: 0 auto;
  }
  @media ${device.tabletMax} {
    padding-bottom: 0;
  }

  .o-header {
    padding-bottom: 48px;

    p {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;

      @media ${device.laptopMedium} {
        font-size: 2.5rem;
        line-height: 3rem;

        margin: auto;
      }
    }
  }
  img {
    margin: 0 auto;
    display: table;

    @media ${device.laptopMedium} {
      width: 96%;
    }
  }

  .generic-slider-no-image {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    max-width: 968px;

    @media ${device.laptopMedium} {
      padding: 0 1rem;
    }

    @media ${device.extraLargeDesktop} {
      max-width: 1008px;
    }

    .swiper-pagination {
      bottom: unset;
      top: 100px;
      background-color: #00ffd9;
      height: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      text-align: center;
      transition: 0.3s opacity;
      z-index: 10;

      @media ${device.laptopMedium} {
        top: 50px;
      }

      .swiper-progress-bar-fill {
        position: absolute;
        height: 8px;
        z-index: -1;
        background-color: #7323ca;
      }

      .swipper-prev-selected {
        background-color: #7323ca !important;
      }

      .swiper-pagination-bullet-active {
        background-color: #7323ca !important;
      }

      .swiper-pagination-bullet {
        width: 68px;
        height: 68px;
        margin: 0px !important;
        opacity: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 100%;
        background-color: #00ffd9;

        &:before {
          content: "";
          background-size: 57%;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        &.swiper-pagination-bullet-active {
          background-color: #7323ca;
        }
      }
    }
    .swiper-pagination-bullets {
      left: 0;
      width: 100%;

      span {
        @media ${device.laptopMedium} {
          width: 45px !important;
          height: 45px !important;
        }
      }
    }

    .swiper-wrapper {
      margin-top: 150px;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      box-sizing: content-box;

      @media ${device.laptopMedium} {
        margin-top: 75px;
        height: auto;
      }

      .swiper-slide {
        margin: 20px auto;

        @media ${device.laptopMedium} {
          width: 100% !important;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      .item {
        max-width: 950px;
        margin: auto;

        @media ${device.ipadair} and ${device.laptopair2max} {
          max-width: unset;
        }

        .item__content {
          margin-top: 30px;
          display: flex;
          margin: auto;
          @media ${device.laptop} {
            width: 100%;
          }

          @media ${device.laptopMedium} {
            display: block;
            width: 100%;
          }

          @media ${device.ipadair} and ${device.laptopair2max} {
            width: 784px;
            float: left;
          }

          .item__icon {
            min-width: 80px;
            margin-right: 34px;

            @media ${device.laptopMedium} {
              display: block;
              margin: 16px auto;
              width: 80px;
            }

            img {
              width: 100%;
              height: auto;
              margin: auto;
              display: block;
              vertical-align: middle;
              border-style: none;

              @media ${device.laptopMedium} {
                width: 50%;
              }
            }
          }

          p {
            color: #000000;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;

            @media ${device.laptopMedium} {
              text-align: center;
              font-size: 1.8rem;
              line-height: 2.6rem;
              padding-right: 0;
            }
          }
        }
      }
    }
    .slider-arrow-container {
      @media ${device.laptopMedium} {
        margin-top: 10px;
      }

      .slider-btn {
        @media ${device.laptopMedium} {
          width: 75px;
          height: 52px;
          margin: 0px 10px;
        }
        &:hover {
          transition: ease-in 0.5s !important;
        }
      }
    }
  }
`;

export const Video2 = styled.div`
  .video-section {
    .o-container {
      .video-section {
        background-color: #fff;
        border-bottom: none;

        @media ${device.laptopMedium} {
          padding: 44px 20px 0;
          padding-left: 0;
          padding-right: 0;
        }

        .video-section__inner-content {
          @media ${device.laptopMedium} {
            padding-bottom: 30px;
          }

          .o-header {
            color: #000000;
          }

          p {
            color: #000000;

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
              line-height: 2.2rem !important;
              width: 99% !important;
            }
          }

          .o-button {
            &.video-section__inner-content__video-btn {
              background: #8000be;
              color: #fff;
              border: 1px solid #fff;

              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                margin-top: 20px;
              }

              @media ${device.laptopMedium} {
                margin-left: 15px;
              }

              i {
                vertical-align: baseline;

                &:before {
                  height: 10px;
                }
              }

              &:hover {
                background: #fff;
                color: #8000be;
                border-color: #fff;
                font-weight: 700;
              }

              span {
                font-size: 2.2rem;
              }
            }
          }
        }
      }
    }
  }
`;
