export const patientIRD: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  banner: "Banner - HTML - IRD - DTC - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  footerRef: "Reference - IRD - DTC - Retina Spain",
  video1: "Video Section 1 - IRD - DTC - Retina Spain",
  video2: "Video Section 2 - IRD - DTC - Retina Spain",
  videoHeading2: "Video heading 2 - IRD - DTC - Retina Spain",
  infographic: "Infographic Section - IRD - DTC - Retina Spain",
  footerCallout: "Navigational Callout - IRD - DTC - Retina Spain",
  ifYouDoSection: "If You Do - IRD - DTC - Retina Spain",
  hardToTell: "It's Hard Tell -  IRD - DTC - Retina Spain",
  accordionSection: "Accordion Section - IRD - DTC - Retina Spain",
  irdDefines: "IRD Defines - IRD - DTC - Retina Spain",
  familyHistory: "Family history alone - IRD - DTC - Retina Spain",
  calloutSection: "Callout Section - IRD - DTC - Retina Spain",
  whatAreGenes: "What Are Genes - IRD - DTC - Retina Spain",
  slider: "Slider - IRD - DTC - Retina Spain",
  closerLook: "Let’s take a closer look - IRD - DTC - Retina Spain",
  backToTopGTM: "back to top - inherited retinal diseases",
  video1Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Genes 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Genes 101"
      }
    ]
  },
  video2Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "inheritance 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Herencia 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "inheritance 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Herencia 101"
      }
    ]
  },
  sliderAttrs: {
    bulletGTMLabels: [
      'inside the eye graphic - step 1',
      'inside the eye graphic - step 2',
      'inside the eye graphic - step 3'
    ],
    bulletAriaLabels: [
      'Imagen 1',
      'Imagen 2',
      'Imagen 3'
    ],
    prevBtnGTMLabel: {
      'data-gtm-event-label': 'inside the eye graphic - previous',
      'className': 'gtm-link-arrow-left',
      'aria-label': 'Previous slide'
    },
    nextBtnGTMLabel: {
      'data-gtm-event-label': 'inside the eye graphic - next',
      'className': 'gtm-link-arrow-right',
      'aria-label': 'Next slide'
    }
  },
  footerClassName: "inherited-retinal-diseases-footer"
}
